.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  display: block;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

a {
  color: rgb(0, 97, 133);
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.blog {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 300;
}


.blog h1 {
  font-size: 34px;
  font-weight: 500;
}
.blog h2 {
  font-size: 28px;
  font-weight: 500;
}
.blog h3 {
  font-weight: 400;
  font-size: 24px;
}

.blog h4 {
  font-size: 20px;
  font-weight: bold;
}

.blog li {
  line-height: 1.2;
}

.button {
  padding: 10px;
  color: white;
  background-color: #0c8e25;
  border-radius: 6px;
}

.button:hover {
  color: #aeaeae;
}

a:hover {
  color: rgb(12, 142, 37);
}

.underlineAnimation a {
  font-weight: 500;
}

.underlineAnimation a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: rgb(12, 142, 37);
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.underlineAnimation a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.blurOverlay {
  opacity: 0;
  /* border: 1px solid rgba(0,0,0,0.1); */
  /* border-radius: 10px; */
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}
.blurOverlay:hover {
  opacity: 1;
  /* color: rgb(0, 97, 133); */
}
.blur {
  transition: 0.5s ease;
}

.blurOverlay:hover ~ .blur {
  filter: blur(0px);
}


.small-text {
  font-size: 12px;
}

.shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 3px 3px 10px 0 #000000;
    top: 0;
    left: 0;
}

.medium-text {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 400;
}

.large-text {
  font-size: 26px;
  letter-spacing: 1px;
  font-weight: 400;
}

.bold {
  font-weight: bold;
}

.huge-text {
  font-size: 40px;
  letter-spacing: 2px;
  font-weight: 500;
}

.light-weight {
  font-weight: 300;
}

.regular-weight {
  font-weight: 400;
}

.bold-weight {
  font-weight: 500;
}

.topToBottomGrid {
  overflow-x: scroll;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
}

.timelineCell {
  height:100%;
  width:100%;
  object-fit: cover;
  object-position: center;
  display: inline-block;
  padding: 2px;
}

.fillParent {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.darkOverlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.unhighlighted {
  opacity: 0.3;
}

.highlighted {
  opacity: 1;
}

.centralizedDiv {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.absoluteCentered {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.imgWithOverlay {

}

.imgOverlay {
  display: unset;
  right: 0px;
  left: 0px;
  bottom:0px;
  position: absolute;
  opacity: 0.5;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  color:white;
}

.imgDescription {
  color: white;
  font-size: 20px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.roundedCorners {
  border-radius: 4px;
  border: 4px solid;
}

.imgContentFit {
  display: block;
  height: auto;
  margin: 0 auto;
  max-height: 80vh;
  max-width: 80vw;
}

.imgOverlay:hover {
  opacity: 1;
}

.imgWithOverlay:hover ~ .imgOverlay {
  opacity: 1;
}

.faderow {
  width: 100%;
  max-height:60px;
  overflow:hidden;
  content: "";
  position:relative;
}

.clearfix:after {
   content: " ";
   display: block;
   clear: both;
}

.clickable {
  cursor: pointer;
}

.fadable {
  transition: opacity 0.5s linear;
}

.hiddenScrollbar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.hiddenScrollbar::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.centerAndFillImage {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
}

.centerAndFillImage img {
    flex-shrink: 0;
    height: 100%;
}

.faderow:before {
    content:'';
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    background:linear-gradient(rgba(255,255,255,0) 30px, white);
}


@media screen and (min-width: 0px) and (max-width: 500px) {
  .largeScreenOnly{ opacity: 0; }  /* show it on smaller screen */
  .smallScreenOnly{ opacity: 1; }  /* show it on smaller screen */
}
@media screen and (min-width: 501px) and (max-width: 1024px) {
  .largeScreenOnly{ opacity: 1; }   /* hide it on larger screens */
  .smallScreenOnly{ opacity: 0; }  /* show it on smaller screen */
}